/**
* Team Section Component
* Created by Emmanuella Albuquerque on 2023/03/13.
*/

import { Avatar } from '../Avatar';
import marcus from '../../images/team/marcus.png'; 
import ciro from '../../images/team/ciro.png'; 
import mario from '../../images/team/mario.png'; 
import eduardo from '../../images/team/eduardo.png'; 
import lucas from '../../images/team/lucas.png'; 
import manu from '../../images/team/manu.png'; 
import molecules from '../../images/molecules.png'; 
import './styles.css';

export function TeamSection() {
  return(
    <section id="team" className="team">
      <h1>Team</h1>
      <div className="image_wrapper_top">
        <img src={molecules} alt="molecules" />
      </div>
      <div className="members-container">
        <div className="coordinator_container">
          <h2>Coordinator</h2>
          <Avatar image={marcus} name="Marcus Tullius Scotti"  />
        </div>

        <div className='divider'></div>

        <div className="devs_container">
          <h2>Devs</h2>
          <Avatar image={ciro} name="Ciro Gomes"  />
          <Avatar image={mario} name="Mario Junior"  />
          <Avatar image={eduardo} name="Eduardo Henrique"  />
          <Avatar image={lucas} name="Lucas Ferreira"  />
          <Avatar image={manu} name="Emmanuella Albuquerque"  />
        </div>
      </div>

      <div className="image_wrapper_bottom">
        <img src={molecules} alt="molecules" />
      </div>
    </section>
  );
}
