import React from 'react';
import { slide as Menu } from 'react-burger-menu';
import './styles.css';
import './basic-sidebar-style.css';

export default function Sidebar() {
  function showSettings (event) {
    event.preventDefault();
  }

  return (
    <Menu className="sidebar" right>
      <a href="#how-to-cite" className="menu-item">
        How to cite
      </a>

      <a href="#about" className="menu-item">
        About
      </a>

      <a href="#team" className="menu-item">
        Team
      </a>

      <a href="#contact" className="menu-item">
        Contact
      </a>

      <span className="menu-item">
        Help
      </span>
    </Menu>
  );
};
