
import logoCell from '../../images/img-smartphone.png'
import playStore from '../../images/logo-playStore.png'
import './styles.css';

export function SectionSmarthPhone() {
  return (
    <section className='container-smarthphone'>

      <div className='org-divs-info-app'>
        <div className='org-divs-info-app-texts'>
          <h2>Download our APP!</h2>

          <p>
            MolPredictX: your ally in molecular research! Manages predictive models published
            in scientific articles, predicts molecules qualitatively, provides quantitative
            probability of activity, and allows downloading of predictions in .csv.
            Download now and facilitate your research in the molecular field!
          </p>

          <a href="https://play.google.com/store/apps/details?id=com.cirogg.molpredict_app" 
            target="_blank" rel="noopener noreferrer"
          >
            <img src={playStore} alt="Dowload APP" className='img-playstore' />
          </a>

        </div>

        <div className='info-img-cell'>

          <img src={logoCell} alt="Dowload APP" />
        </div>

      </div>

    </section>
  );
}
