/**
* Avatar Component
* Created by Emmanuella Albuquerque on 2023/03/13.
*/

import './styles.css';

export function Avatar({image, name}) {
  return(
    <div className="avatar-container">
      <img src={image} alt="avatar" />
      <p>{name}</p>
    </div>
  );
}
