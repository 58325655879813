/**
* Divider Component
* Created by Emmanuella Albuquerque on 2023/03/16.
*/

import './styles.css';

export function Divider({ vertical }) {
  return (
    <div className={`${ vertical ? "vertical_divider" :  "horizontal_divider"}`}>
    </div>
  );
}
